import React from 'react';
import GlobalStyles from './GlobalStyles';
import { ThemeProvider } from './ThemeContext';
import "@fontsource/bitter";
import "../assets/fonts/Inter-Bold.woff2";
import "../assets/fonts/Inter-Medium.woff2";

function App({children}: {children:any}) {
  return (
    <ThemeProvider>
      <GlobalStyles />
      {children}
    </ThemeProvider>
  );
}

export default App;